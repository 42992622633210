$color-main: #0063a5;
$color-secondary: #409cdf;
$color-highlight: #fc9b18;
$color-highlight: $color-main;


// $base-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
// $base-font-family: 'Heebo', sans-serif;
// $base-font-family: 'Source Sans Pro', sans-serif;
$base-font-size:   16px !default;
$base-font-weight: 300 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

// $display-font-family: 'Cabin', sans-serif;
// $display-font-family: 'Oswald', sans-serif;
// $display-font-family: 'Roboto Slab', sans-serif;
// $display-font-family: 'Poppins', sans-serif;
$display-font-family: 'Source Sans Pro', sans-serif;
$base-font-family: $display-font-family;

$spacing-unit:     30px !default;

$text-color:       #111 !default;
$background-color: #fdfdfd !default;
$brand-color:      $color-main !default;

$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

// Width of the content area
$content-width:    1500px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;

$on-medium:        $on-palm !default;
$on-large:         $on-laptop !default;